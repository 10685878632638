import { defineComponent, onMounted, ref, watch } from 'vue';
import { triggerConfetti } from '../../scripts/confetti.utility';
import BaseButton from '../atoms/BaseButton.vue';
import LoadingIndicator from '../atoms/LoadingIndicator.vue';
export default defineComponent({
    name: 'JobCheckValidator',
    components: {
        BaseButton,
        LoadingIndicator,
    },
    props: {
        buttonText: String,
        contactImage: String,
        validationEndpoint: String,
        homeUrl: String,
    },
    setup(props) {
        const validated = ref(false);
        const validationSuccess = ref(false);
        const canvas = ref(null);
        const goToHome = () => {
            if (props.homeUrl)
                location.href = props.homeUrl;
        };
        onMounted(async () => {
            const token = new URLSearchParams(location.search).get('token');
            if (!token)
                return;
            const setError = () => {
                validated.value = true;
                validationSuccess.value = false;
            };
            try {
                const validationResponse = await fetch(`${props.validationEndpoint}?verificationCode=${token}`);
                if (validationResponse.ok) {
                    const { valid } = await validationResponse.json();
                    validated.value = true;
                    validationSuccess.value = valid;
                }
                else {
                    setError();
                }
            }
            catch (_) {
                setError();
            }
        });
        watch(validationSuccess, () => triggerConfetti(canvas));
        return { validated, goToHome, validationSuccess, canvas };
    },
});
